import React from "react";
import Navbar from "./Navbar.js";

export default function Header() {
  return (
    <>
      {/* <div className="header-main">KAPAT RESEARCH GROUP</div> */}
      <Navbar />
    </>
  );
}
